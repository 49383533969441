import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_jHu3rOuUhN6AXyvOi29ZFLh2j7C02FHRTAbC1X7xTso from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CjsP5VZtGlLCkZ9lqywkYEzt_qOuW1rwcxhLRJY_BWg from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_S2LMEO3VujrJWvRoWLTo2nca4bfDYWl5yUi1ph_jEzk from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig__QlIgs4p1UgoLAb3AxeMp0K0wLSLcg9rKpHg7JBtqfw from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.1.5_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import supabase_client_JDwSt5mBCdLW05KFx9sazlRsQWWKUjM8lxy8Dlef6lI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.5.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_m9tsTiIWMcB_8LIYhxzUSwcW9Qu_hSthVNepi4JBmtw from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_R7cUk9UNu09_o6sMg0y7K5OrEnpimDPgh1nc1o8duQs from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_K_vEnihujU4lw8f2SEIwF3C8OkfiJUXDkXfx45aaulQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9ZQHTFGY6i6VWLPqr6HsdoZ5Yo7WW1RxQar3MKsU584 from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import preview_client_muaO847d1xZF88c0UhaSp8MAbmAd3tbb_Aql_sJskOo from "/vercel/path0/node_modules/.pnpm/@nuxt+content@3.3.0_magicast@0.3.5_typescript@5.8.2/node_modules/@nuxt/content/dist/runtime/plugins/preview.client.js";
import components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_rD3gdxhk6YIMscGCM4l1zQNYZnQg6RrU_KpGkBXgtFU from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CTz1dCW0mIhOMd9CIA0WC1_x9wDBcROb22_wDKfPIYo from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@8.4.0_@bugsnag+core@8.2.0_magicast@0.3.5_vue-router@4.5.0_vue@3.5.13_typescript@5.8.2__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/pwa-icons-plugin.ts";
import pwa_client_FARv4hHd_BQJkLWds78ByXsz7xJV2OM2J9Owh5Tn5tY from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_lightni_848935c7cec8b30a78270a6d8087b3a0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_0207495bb835a6f5ae7860b54eb3353f/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import colors_70P_mFoprWqXPvMRYOgSYh_CZNYT52wrwOZWe9qOCcE from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@3.0.0-beta.3_@babel+parser@7.26.10_change-case@5.4.4_db0@0.3.1_better-sqlite3@_0f1d12c6e2f5ff57bb47e74e649b9d0d/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Yer9A6_GIriawcWuStDGoLPAO9hCXj0X1J1b9yz8mUE from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.2_@types+node@22.13.10_jiti@2.4.2_lightningcs_7ac649dc72d4b5286c141c4117fbb215/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import error_handler_2ZjYitRpTBNLhw0wrBhevjRaap9OT2g1F_l1UrtiJdU from "/vercel/path0/app/plugins/error-handler.js";
import hasUser_YsvApN6A7ojcCZllR18Kc7wa_ioaxy9SOldLKROpsT0 from "/vercel/path0/app/plugins/hasUser.ts";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_jHu3rOuUhN6AXyvOi29ZFLh2j7C02FHRTAbC1X7xTso,
  unhead_CjsP5VZtGlLCkZ9lqywkYEzt_qOuW1rwcxhLRJY_BWg,
  router_S2LMEO3VujrJWvRoWLTo2nca4bfDYWl5yUi1ph_jEzk,
  _0_siteConfig__QlIgs4p1UgoLAb3AxeMp0K0wLSLcg9rKpHg7JBtqfw,
  supabase_client_JDwSt5mBCdLW05KFx9sazlRsQWWKUjM8lxy8Dlef6lI,
  payload_client_m9tsTiIWMcB_8LIYhxzUSwcW9Qu_hSthVNepi4JBmtw,
  navigation_repaint_client_R7cUk9UNu09_o6sMg0y7K5OrEnpimDPgh1nc1o8duQs,
  check_outdated_build_client_K_vEnihujU4lw8f2SEIwF3C8OkfiJUXDkXfx45aaulQ,
  chunk_reload_client_9ZQHTFGY6i6VWLPqr6HsdoZ5Yo7WW1RxQar3MKsU584,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  preview_client_muaO847d1xZF88c0UhaSp8MAbmAd3tbb_Aql_sJskOo,
  components_plugin_4kY4pyzJIYX99vmMAAIorFf3CnAaptHitJgf7JxiED8,
  prefetch_client_rD3gdxhk6YIMscGCM4l1zQNYZnQg6RrU_KpGkBXgtFU,
  plugin_CTz1dCW0mIhOMd9CIA0WC1_x9wDBcROb22_wDKfPIYo,
  pwa_icons_plugin_OtOZ6CGly_Vz5_PCGGLA9qHLz2Y5_d5czYAX7q_3Lug,
  pwa_client_FARv4hHd_BQJkLWds78ByXsz7xJV2OM2J9Owh5Tn5tY,
  plugin_NPi_Ixo1sLCQtQhYgcq9HdVO7xR0RGTjgeae7FdmR4E,
  colors_70P_mFoprWqXPvMRYOgSYh_CZNYT52wrwOZWe9qOCcE,
  plugin_client_hfhi7EvGMZ1W8_oCOXoqkQnkKm8rhjarvv8OwRMckSo,
  plugin_Yer9A6_GIriawcWuStDGoLPAO9hCXj0X1J1b9yz8mUE,
  error_handler_2ZjYitRpTBNLhw0wrBhevjRaap9OT2g1F_l1UrtiJdU,
  hasUser_YsvApN6A7ojcCZllR18Kc7wa_ioaxy9SOldLKROpsT0
]