import { defineStore } from 'pinia'

export const useSchoolUsersStore = defineStore('schoolUsersStore', {
  state: () => ({
    schoolUsers: [] as SchoolUser[],
  }),
  getters: {
    getSchoolUsers: (state) => {
      return () => state.schoolUsers
    },
  },
  actions: {
    addSchoolUsers(users: SchoolUser[]) {
      users.forEach((newUser: SchoolUser) => {
        if(!this.schoolUsers.find(existingUser => existingUser.id === newUser.id )){
          this.schoolUsers.push(newUser)
        }
      })
    },
    addSchoolUser(user: SchoolUser) {
      this.schoolUsers.push(user)
    },
    removeSchoolUser(user: SchoolUser) {
      this.schoolUsers = this.schoolUsers.filter((u) => u.id !== user.id)
    },
    deleteSchoolUsers() {
      this.schoolUsers = []
    }
  },
  persist: true,
})
