
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accept_45invite_gUyI_459s0odyDAY4p9EFcCcO0Dou8xIz6EagwjLeeo4Meta } from "/vercel/path0/app/pages/accept-invite.vue?macro=true";
import { default as index9G4VLPUwZG_MWL_yfCXed24BbC_qA4f0m8Z0MWW_4563AMeta } from "/vercel/path0/app/pages/classes/[game]/index.vue?macro=true";
import { default as play5ofcDDdhlgAaHgMKD0AnQpzorfcqs1t0NQESg3ELbfQMeta } from "/vercel/path0/app/pages/classes/[game]/play.vue?macro=true";
import { default as index_lB13k37c9FuEb9IUvB2cCD3uPdGQAT6TLE_45PS4YlU4Meta } from "/vercel/path0/app/pages/classes/index.vue?macro=true";
import { default as confirmDMGxLvu64ZVWvqMiQgtpXZ5V_Pn_457OVfBon36zdgA6AMeta } from "/vercel/path0/app/pages/confirm.vue?macro=true";
import { default as create_45profileSbHY383rvR2pnuYvEAqmW6Ky2wmJorbPxLibpvdFSj0Meta } from "/vercel/path0/app/pages/create-profile.vue?macro=true";
import { default as indexv3dziSY8lvXRap5UQAfl_yDmv1eKDmf4o_45aKhTsLMjMMeta } from "/vercel/path0/app/pages/dashboard/admin/index.vue?macro=true";
import { default as invite9S8cMVKLuKO4jnnGwab06sEbT0KgIF_xsom41EOe7pwMeta } from "/vercel/path0/app/pages/dashboard/admin/invite.vue?macro=true";
import { default as indexyj3J6KtSpGzp_45BIpLTj3mN_45PHJ1YrpYjw3t6rN5_E68Meta } from "/vercel/path0/app/pages/dashboard/index.vue?macro=true";
import { default as indexuCy1lkjhNVoaFPZkSF2vEqdJ5hckZ3ihC_45cauJIOaogMeta } from "/vercel/path0/app/pages/dashboard/student/index.vue?macro=true";
import { default as insights9ilEbYoyYXnMEm24wFTfdmX1_45Nsx8YCCnx5IXld7UVcMeta } from "/vercel/path0/app/pages/dashboard/student/insights.vue?macro=true";
import { default as system_45adminaNN3spVAPyvKuPjYpzRCOQ7Semi6YLgRjAWEZyEN4h0Meta } from "/vercel/path0/app/pages/dashboard/system-admin.vue?macro=true";
import { default as index4eEEUtK53gsbwKFCi0YfbzbLd3OTfbr4avc9oCfCMYYMeta } from "/vercel/path0/app/pages/dashboard/teacher/index.vue?macro=true";
import { default as invitelOM6b2Bhqd8D3bl4_uBrTwsMHj7fHAgVT_BvbaVqV7AMeta } from "/vercel/path0/app/pages/dashboard/teacher/invite.vue?macro=true";
import { default as indexXNEFQ_45BjAwM6CKVywW7WXWaOQOf9WzaBic0wLTXHAssMeta } from "/vercel/path0/app/pages/index.vue?macro=true";
import { default as loginBmyj_vfPTDaEDqaiGpr84MHO3dLRczQV7ag0uCpdfccMeta } from "/vercel/path0/app/pages/login.vue?macro=true";
import { default as loginOTP4r_XfCf7Y8_S8m0SqSyvEPoO6TP1vv8C5joF6K8aTXcMeta } from "/vercel/path0/app/pages/loginOTP.vue?macro=true";
import { default as register1hGWkzEHinvMBRBzAThPoMpWiGdOhVcz_J9o_RPyOEwMeta } from "/vercel/path0/app/pages/register.vue?macro=true";
export default [
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/vercel/path0/app/pages/accept-invite.vue")
  },
  {
    name: "classes-game",
    path: "/classes/:game()",
    component: () => import("/vercel/path0/app/pages/classes/[game]/index.vue")
  },
  {
    name: "classes-game-play",
    path: "/classes/:game()/play",
    meta: play5ofcDDdhlgAaHgMKD0AnQpzorfcqs1t0NQESg3ELbfQMeta || {},
    component: () => import("/vercel/path0/app/pages/classes/[game]/play.vue")
  },
  {
    name: "classes",
    path: "/classes",
    component: () => import("/vercel/path0/app/pages/classes/index.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/vercel/path0/app/pages/confirm.vue")
  },
  {
    name: "create-profile",
    path: "/create-profile",
    component: () => import("/vercel/path0/app/pages/create-profile.vue")
  },
  {
    name: "dashboard-admin",
    path: "/dashboard/admin",
    meta: indexv3dziSY8lvXRap5UQAfl_yDmv1eKDmf4o_45aKhTsLMjMMeta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/admin/index.vue")
  },
  {
    name: "dashboard-admin-invite",
    path: "/dashboard/admin/invite",
    meta: invite9S8cMVKLuKO4jnnGwab06sEbT0KgIF_xsom41EOe7pwMeta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/admin/invite.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-student",
    path: "/dashboard/student",
    meta: indexuCy1lkjhNVoaFPZkSF2vEqdJ5hckZ3ihC_45cauJIOaogMeta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/student/index.vue")
  },
  {
    name: "dashboard-student-insights",
    path: "/dashboard/student/insights",
    meta: insights9ilEbYoyYXnMEm24wFTfdmX1_45Nsx8YCCnx5IXld7UVcMeta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/student/insights.vue")
  },
  {
    name: "dashboard-system-admin",
    path: "/dashboard/system-admin",
    meta: system_45adminaNN3spVAPyvKuPjYpzRCOQ7Semi6YLgRjAWEZyEN4h0Meta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/system-admin.vue")
  },
  {
    name: "dashboard-teacher",
    path: "/dashboard/teacher",
    meta: index4eEEUtK53gsbwKFCi0YfbzbLd3OTfbr4avc9oCfCMYYMeta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/teacher/index.vue")
  },
  {
    name: "dashboard-teacher-invite",
    path: "/dashboard/teacher/invite",
    meta: invitelOM6b2Bhqd8D3bl4_uBrTwsMHj7fHAgVT_BvbaVqV7AMeta || {},
    component: () => import("/vercel/path0/app/pages/dashboard/teacher/invite.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/app/pages/login.vue")
  },
  {
    name: "loginOTP",
    path: "/loginOTP",
    component: () => import("/vercel/path0/app/pages/loginOTP.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/vercel/path0/app/pages/register.vue")
  }
]