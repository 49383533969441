import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.13.10_better-sqlite3@11.9.0_db0@0.3.1__3bf8cc60cfe9718b0e29ca848910683b/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-admin": () => import("/vercel/path0/app/middleware/authAdmin.ts"),
  "auth-student": () => import("/vercel/path0/app/middleware/authStudent.ts"),
  "auth-system-admin": () => import("/vercel/path0/app/middleware/authSystemAdmin.ts"),
  "auth-teacher": () => import("/vercel/path0/app/middleware/authTeacher.ts")
}