import { defineStore } from 'pinia'
import type { terms } from '~/enums'

function createProgressKey({
  userId,
  term,
  grade,
  subject,
  gameId,
  level,
}: GameProgressKey): string {
  return `${userId}_${subject}_${grade}_${term}_${gameId}_${level}`
}

export const useGameProgressStore = defineStore('gameProgressStore', {
  state: () => ({
    // A normalized map of game progress records.
    // Each record is of type GameProgressRecord (which extends GameProgress).
    progress: {} as Record<string, GameProgressRecord>,
    syncTimer: null as ReturnType<typeof setInterval> | null,
  }),
  getters: {
    // Get all progress records for a specific user, grade, subject, and term.
    getProgressByTerm:
      (state) =>
      (userId: string, subject: string,  grade: string, term: terms): GameProgressRecord[] => {
        return Object.values(state.progress).filter(
          (record) =>
            record.userId === userId &&
            record.subject === subject &&
            record.grade === grade &&
            record.term === term &&
            true
        )
      },
    // Get a specific progress record by gameId and level.
    getProgressByGameAndLevel:
      (state) =>
      (
        userId: string,
        subject: string,
        grade: string,
        term: terms,
        gameId: string,
        level: number
      ): GameProgressRecord | undefined => {
        const key = createProgressKey({ userId, subject, grade, term, gameId, level })
        return state.progress[key]
      },
  },
  actions: {
    async getOnlineGameProgress(userId: string,  subject: string, grade: string, term: terms) {
      if (!userId) return

      const { data } = await $fetch<{ data: GameProgressRecord[] }>('/api/gameProgress/gameProgress', {
        method: 'GET',
        params: { userId, subject, grade, term},
      })
      const records = data

      records?.forEach((record) => {
        // Create the composite key.
        const key = createProgressKey({
            userId: record.userId,
            subject,
            grade,
            term: record.term as terms,
            gameId: record.gameId,
            level: record.level,
        })
        this.progress[key] = record
      })
    },
    // Increase game score and update progress.
    updateProgress(
      gameId: string,
      userId: string,
      subject: string,
      grade: string,
      term: terms,
      strand: string,
      substrand: string,
      level: number,
      levelCompletion: number,
      unlocked: boolean,
      isSynced: boolean,
      isCorrect: boolean,
      timeTaken: number,
    ) {
      const LEVEL_COMPLETED = 100
      const POINTS_PER_QUESTION = LEVEL_COMPLETED / 5

      const key = createProgressKey({ userId, subject, grade, term, gameId, level })
      let record = this.progress[key]

      if (!record) {
        // Create a new record if one doesn't exist.
        record = {
          userId,
          subject,
          grade,
          term,
          gameId,
          level,
          levelCompletion: POINTS_PER_QUESTION,
          correct: isCorrect ? 1 : 0,
          incorrect: isCorrect ? 0 : 1,
          avgTimeTaken: timeTaken,
          unlocked,
          strand,
          substrand,
          isSynced
        } as GameProgressRecord
        this.progress[key] = record
      } else {
        record.levelCompletion += levelCompletion
        const answeredQuestions = record.correct + record.incorrect
        record.avgTimeTaken = Math.round((record.avgTimeTaken * answeredQuestions + timeTaken) / (answeredQuestions + 1))
        record.correct += isCorrect ? 1 : 0
        record.incorrect += isCorrect ? 0 : 1
        record.isSynced = false
      }

      // Unlock next level if threshold reached.
      if (record.levelCompletion >= LEVEL_COMPLETED) {
        const nextKey = createProgressKey({ userId, grade, subject, term, gameId, level: record.level + 1 })
        if (!this.progress[nextKey]) {
          this.progress[nextKey] = {
            userId,
            subject,
            grade,
            term,
            gameId,
            level: record.level + 1,
            levelCompletion: 0,
            correct: 0,
            incorrect: 0,
            avgTimeTaken: 0,
            unlocked: true,
            strand: record.strand,
            substrand: record.substrand,
            isSynced: false
          } as GameProgressRecord
        }
      }
    },
    async syncUnsyncedProgress() {
      // Gather all unsynced records.
      const unsyncedRecords = Object.values(this.progress).filter(record => record.isSynced === false)
      if (!unsyncedRecords.length) return
      // Create an array of promises to sync each record in parallel.
      const syncPromises = unsyncedRecords.map(record => {
        return $fetch("/api/gameProgress/gameProgress", {
          method: "PUT",
          body: record,
        })
          .then(() => {
            // Mark the record as synced.
            record.isSynced = true
          })
          .catch(error => {
            console.error("Sync failed for record", record, error)
            // Leave record unsynced for future attempts.
          })
      })

      // Run all sync operations in parallel.
      await Promise.all(syncPromises)
    },
    // Start a timer to periodically check for unsynced progress.
    startSyncTimer() {      
      this.syncTimer = setInterval(() => {
        if (navigator.onLine) {
          this.syncUnsyncedProgress()
        }
      }, 10000)
    },
    // Stop the sync timer if needed.
    stopSyncTimer() {
      if (this.syncTimer) {
        clearInterval(this.syncTimer)
        this.syncTimer = null
      }
    },
  },
  persist: true,
})
