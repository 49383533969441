<script setup lang="ts">
import { Grades, roles, Subjects } from "~/enums";
import { useGamesStore } from "~/stores/games";
import { useCurrentRoleStore } from "~/store/currentRole";
import { useSchoolUsersStore } from "~/store/schoolUsers";

const gamesStore = useGamesStore();
const currentRoleStore = useCurrentRoleStore();
const { currentStudent, currentRole } = storeToRefs(currentRoleStore);
const supabase = useSupabaseClient();
const user = useSupabaseUser();
const drawerNavStudent = [
  {
    id: "home",
    label: "Home",
    icon: "i-heroicons-home-solid",
    color: "text-purple-700",
    to: "/",
  },
  {
    id: "classes",
    label: "Classes",
    icon: "i-heroicons-academic-cap-solid",
    color: "text-amber-500",
    to: "/classes",
  },
];

const drawerNavTeacher = [
  {
    id: "dashboard",
    label: "Dashboard",
    icon: "i-heroicons-home-solid",
    color: "text-green-700",
    to: "/dashboard",
  },
  {
    id: "classes",
    label: "Classes",
    icon: "i-heroicons-academic-cap-solid",
    color: "text-amber-500",
    to: "/classes",
  },
];

const drawerNav = computed(() => {
  if (
    currentRole.value.role === roles.ADMIN ||
    currentRole.value.role === roles.TEACHER
  ) {
    return drawerNavTeacher;
  } else {
    return drawerNavStudent;
  }
});

const schoolUsersStore = useSchoolUsersStore();
const schoolRoles = useGetUserSchools();
const selectedSchool = ref("");
const availableSchoolRoles = ref([] as SchoolRole[]);

const { setCurrentRole } = useCurrentRoleStore();

const setUserRole = (schoolRole: SchoolRole) => {
  if (!schoolRole) return;
  setCurrentRole(schoolRole);
};

watch(
  () => selectedSchool.value,
  () => {
    availableSchoolRoles.value = schoolRoles?.filter(
      (schoolRole: SchoolRole) => schoolRole.schoolId === selectedSchool.value
    );
    if (availableSchoolRoles.value.length === 1) {
      setUserRole(availableSchoolRoles.value[0] ?? ({} as SchoolRole));
    }
  }
);
onMounted(() => {
  // Load all the games (if already loaded, the store returns)
  gamesStore.initializeGames(Grades.B1, Subjects.MATHEMATICS, useGetTerm());

  // Select a school if user is logged in
  if (schoolRoles?.length === 0) {
    if (user.value) {
      navigateTo("/");
      return;
    } else {
      navigateTo("/login");
      return;
    }
  }
  if (schoolRoles?.length === 1) {
    setUserRole(schoolRoles[0]);
    return;
  }
  const differentSchools = new Set(
    schoolRoles.map((schoolRole: SchoolRole) => schoolRole.schoolId)
  ).size;
  if (differentSchools === 1) {
    selectedSchool.value = schoolRoles[0]?.schoolId;
  }
});

const open = ref(false);

defineShortcuts({
  o: () => (open.value = !open.value),
});

const logOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    useBugsnag().notify(error);
    console.log(error);
  }
  currentRoleStore.setCurrentRole({} as SchoolRole);
  currentRoleStore.setCurrentStudent(null);
  open.value = false;
  schoolUsersStore.deleteSchoolUsers();
  navigateTo("/login");
};

const showDashboardCodeModal = ref(false);
</script>

<template>
  <UHeader :toggle="false">
    <template #left>
      <NuxtLink to="/" class="flex items-end text-sm">
        <img src="/WLearn_Logo_Blue.svg" class="h-12 pt-2" /><span class="mb-2"
          >v0.10</span
        >
      </NuxtLink>
    </template>

    <template #right>
      <div class="flex items-center justify-end p-4 cursor-pointer">
        <div
          v-if="user?.email"
          class="no-underline hidden sm:flex items-center"
          :class="[currentStudent?.name ? 'text-blue-500' : '']"
          @click="navigateTo('/dashboard')"
        >
          {{ currentStudent?.name ?? user?.user_metadata.name }}
          <UIcon
            name="i-heroicons-user-circle"
            class="h-6 w-6 lg:ml-4 cursor-pointer"
          />
        </div>
        <NuxtLink
          v-show="!user?.email"
          to="/login"
          class="flex items-center lg:mr-4"
        >
          <UIcon
            name="i-heroicons-user-circle"
            class="h-6 w-6 lg:ml-4 cursor-pointer"
          />
        </NuxtLink>
        <USlideover v-model:open="open">
          <UIcon
            name="i-heroicons-bars-3"
            class="h-8 w-8 ml-4 cursor-pointer"
            @click="open = true"
          />
          <template #header>
            <div class="flex justify-between w-full">
              <NuxtLink to="/">
                <img src="/WLearn_Logo_Blue.svg" class="h-12 pt-2 pl-4" />
              </NuxtLink>
              <UButton
                color="primary"
                variant="outline"
                size="xl"
                icon="i-heroicons-x-mark"
                class="cursor-pointer rounded-full px-3"
                @click="open = false"
              />
            </div>
          </template>
          <template #body>
            <ul>
              <li
                v-for="(item, index) in drawerNav"
                :key="`nav-item-${index}`"
                class="mt-4"
                :class="item.color"
              >
                <NuxtLink
                  class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white rounded-l-lg"
                  :to="item.to"
                  @click="
                    item.to === '/dashboard'
                      ? ((showDashboardCodeModal = true), (open = false))
                      : (open = false)
                  "
                >
                  <div class="flex text-2xl">
                    <UIcon :name="item.icon" class="h-8 w-8 mx-4" />
                    <span class="font-bold">
                      {{ item.label }}
                    </span>
                  </div>
                </NuxtLink>
              </li>
            </ul>
          </template>
          <template #footer>
            <NuxtLink
              v-show="user"
              class="flex flex-col justify-center w-full h-12 py-8 hover:bg-blue-600 hover:text-white cursor-pointer rounded-l-lg"
              @click="logOut"
            >
              <div class="flex text-blue-600 hover:text-white">
                <UIcon
                  name="i-heroicons-arrow-right-on-rectangle-solid"
                  class="h-8 w-8 mx-4"
                />
                <span class="font-bold text-xl"> Sign out </span>
              </div>
            </NuxtLink>
            <NuxtLink
              v-show="!user"
              class="flex flex-col justify-center w-full h-12 py-8 hover:bg-blue-600 hover:text-white cursor-pointer rounded-l-lg"
              @click="navigateTo('/login'), (open = false)"
            >
              <div class="flex text-blue-600 hover:text-white">
                <UIcon
                  name="i-heroicons-arrow-left-on-rectangle-solid"
                  class="h-8 w-8 mx-4"
                />
                <span class="font-bold text-xl"> Login </span>
              </div>
            </NuxtLink>
          </template>
        </USlideover>
        <AuthDashboardCode
          :open="showDashboardCodeModal"
          url="/dashboard"
          @close="showDashboardCodeModal = false"
        />
      </div>
    </template>
  </UHeader>
</template>
