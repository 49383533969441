import { defineStore } from 'pinia'

export const useCurrentRoleStore = defineStore('currentRoleStore', {
  state: () => ({
    currentRole: {} as SchoolRole,
    currentStudent: {} as SchoolUser | null,
  }),
  getters: {
    getCurrentRole: (state) => {
      return () => state.currentRole
    },
    getCurrentStudent: (state) => {
      return () => state.currentStudent
    },
  },
  actions: {
    setCurrentRole(role: SchoolRole) {
      this.currentRole = role
    },
    setCurrentStudent(student: SchoolUser | null) {
      this.currentStudent = student
    },
  },
  persist: true,
})
