import { terms } from '~/enums'

export const useGetTermByWeek = (week: string) => {
  const weekNumber = parseInt(week.split('-')[1], 10)
  if (weekNumber >= 40 && weekNumber <= 52) {
    return terms.FIRST // Starts from October (weekNumber 40) to end December (weekNumber 52)
  } else if (weekNumber >= 1 && weekNumber <= 15) {
    return terms.SECOND // January (weekNumber 1) to April (weekNumber 15)
  } else if (weekNumber >= 15 && weekNumber <= 39) {
    return terms.THIRD // April (weekNumber 28) to July (weekNumber 39)
  } else {
    return terms.THIRD // Ghana should not have a fourth term actually
  }
}

  const getCurrentWeek = () => {
    const now = new Date()
    const startOfYear = new Date(now.getFullYear(), 0, 1)
    const pastDays = Math.floor((now.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000))
    return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7) // Adjust for start of the year
  }

export const useGetTerm = () => {
  const currentWeek = getCurrentWeek()

  if (currentWeek >= 40 && currentWeek <= 52) {
    return terms.FIRST // October (week 40) - December (week 52)
  } else if (currentWeek >= 1 && currentWeek <= 15) {
    return terms.SECOND // January (week 1) - April (week 15)
  } else if (currentWeek >= 16 && currentWeek <= 39) {
    return terms.THIRD // April (week 16) - July (week 39)
  } else {
    return terms.THIRD // Defaults to THIRD (no fourth term in Ghana)
  }
}

