<script setup lang="ts">
const { $pwa } = useNuxtApp();
const gameProgressStore = useGameProgressStore();
function handleOnline() {
  // Immediately try syncing unsynced progress when the connection is back.
  gameProgressStore.syncUnsyncedProgress();
}

onMounted(async () => {
  if ($pwa) {
    await $pwa.install();
  }
  gameProgressStore.startSyncTimer();
  window.addEventListener("online", handleOnline);
});

onUnmounted(() => {
  window.removeEventListener("online", handleOnline);
  gameProgressStore.stopSyncTimer();
});

const colorMode = useColorMode();

const color = computed(() =>
  colorMode.value === "dark" ? "#111827" : "white"
);

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
});

useSeoMeta({
  titleTemplate: "%s - WLearn Academy",
  ogImage: "https://gh.wlearn.me/img/wlearn.webp",
  twitterImage: "https://gh.wlearn.me/img/wlearn.webp",
  twitterCard: "summary_large_image",
});
</script>

<template>
  <UApp>
    <div v-show="$pwa?.needRefresh">
      <span> New content available, click on reload button to update. </span>

      <UButton color="primary" size="xl" @click="$pwa?.updateServiceWorker()">
        Reload
      </UButton>
    </div>
    <NuxtLoadingIndicator />
    <NuxtPwaManifest />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </UApp>
</template>
