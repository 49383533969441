import { defineStore } from 'pinia'
import type { terms } from '~/enums'


export const useGamesStore = defineStore('gamesStore', {
  state: () => ({
    games: {} as Record<string, GameSpecification>,
  }),
  getters: {
    // Return all games as an array.
    allGames: (state) => Object.values(state.games),
    // Filter games by year, subject, and term.
    getGamesByTerm:
      (state) =>
      (grade: string, term: terms): GameSpecification[] => {
        return Object.values(state.games).filter(
          (game) =>
            game.gameReference.grade === grade &&
            game.gameReference.term === term
        )
      },
    // Optionally, get a game by its ID.
    gameById: (state) => (gameId: string): GameSpecification | undefined =>
      state.games[gameId],

    gamesPerWeek: (state) => (grade: string, term: terms): Record<string, GameSpecification[]> => {
      const gamesPerWeek: Record<string, GameSpecification[]> = {}

      // Group games by week.
      Object.values(state.games).forEach((game) => {
        if (!game.gameReference ||
            game.gameReference.grade !== grade ||
            game.gameReference.term !== term ||
            !game.gameReference.week)
          return

        if (!gamesPerWeek[game.gameReference.week]) {
          gamesPerWeek[game.gameReference.week] = []
        }
        gamesPerWeek[game.gameReference.week].push(game)
      })

      // Sort the keys by extracting the numeric part (e.g., "week-1" => 1).
      const sortedKeys = Object.keys(gamesPerWeek).sort((a, b) => {
        const aNum = parseInt(a.replace(/[^0-9]/g, ''))
        const bNum = parseInt(b.replace(/[^0-9]/g, ''))
        return aNum - bNum
      })

      // Reassemble into a new object with keys in sorted order.
      const sortedGamesPerWeek: Record<string, GameSpecification[]> = {}
      sortedKeys.forEach(key => {
        sortedGamesPerWeek[key] = gamesPerWeek[key]
      })

      return sortedGamesPerWeek
      },
    },
  actions: {
    async initializeGames(grade: string, subject:string, term: terms) {
      try {
        const isAlreadyLoaded = Object.values(this.games).some(game => 
          game.gameReference.grade === grade && 
          game.gameReference.term === term
        )
        if (isAlreadyLoaded) return
        const fetchedGames = await queryCollection(subject)
          .where('id', 'LIKE', `${subject}/${subject}/${grade}/${term}%`)
          .all()
        fetchedGames.forEach((game: any) => {
          const match = (game as any).meta.path.match(/\/(week-\d+)/)
          const gameSpec: GameSpecification = {
            gameReference: {
                grade: game.game_reference?.grade,
                term: game.game_reference?.term,
              contentStandard: game.game_reference?.content_standard,
              indicator: game.game_reference?.indicator,
              subject: game.game_reference?.subject,
              strand: game.game_reference?.strand,
              substrand: game.game_reference?.substrand,
              week: match ? match[1] : null,
            },
            gameId: game.game_id,
            gameName: game.game_name,
            gameDescription: game.game_description,
            unlocked: game.unlocked === 'true',
            gameProperties: {
              gameType: game.game_properties?.game_type ?? '',
              operators: game.game_properties?.operators ?? [],
              visualType: game.game_properties?.visual_type ?? '',
              useOperatorName: game.game_properties?.useOperatorName ?? false,
            },
            gameLevels:
              game.game_levels?.map((level: any) => ({
                level: level.level ?? 1,
                range: level.range,
                stepSize: level.step_size,
                arrayLength: level.array_length,
                groupSize: level.group_size,
                questionTypes: level.question_types,
                uniqueShapes: level.unique_shapes,
                shapes: level.shapes,
                hiddenOperand: level.hidden_operand,
                expressionsPerQuestion: level.expressions_per_question,
              })) || [],
            coreCompetences: game.core_competences ?? [],
          }
          this.games[gameSpec.gameId] = gameSpec
        })
      } catch (error) {
        console.error('Error fetching game content:', error)
      }
    },
  },
  persist: true,
})
