<script lang="ts" setup>
const emit = defineEmits(["close"]);
const props = defineProps({
  open: { type: Boolean, required: true, default: false },
  url: { type: String, required: true },
});
const continueButton = ref(null);
const input = ref("");

const submit = () => {
  // @todo clean input
  navigateTo(`${props.url}?key=${input.value}`);
  input.value = "";
  emit("close");
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter" && Number(input.value) > 0) {
    submit();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown);
});
</script>
<template>
  <UModal v-model:open="props.open" :close="false">
    <template #body>
      <h2 class="text-lg">Continue with your teacher's code:</h2>
      <UInput
        v-model="input"
        type="number"
        placeholder="Enter code"
        class="mt-4"
      />
    </template>
    <template #footer>
      <div class="flex justify-between gap-4 w-full">
        <UButton
          label="Cancel"
          color="neutral"
          variant="outline"
          @click="emit('close')"
        />
        <UButton
          ref="continueButton"
          label="Continue"
          color="primary"
          variant="solid"
          @click="submit()"
        />
      </div>
    </template>
  </UModal>
</template>
