export const checksums = {
  "mathematics": "v3.3.0--gjiqrySthi"
}
export const checksumsStructure = {
  "mathematics": "DVGYuiTYqg"
}

export const tables = {
  "mathematics": "_content_mathematics",
  "info": "_content_info"
}

export default {
  "mathematics": {
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "game_reference": "json",
      "game_id": "string",
      "game_name": "string",
      "game_description": "string",
      "unlocked": "string",
      "game_properties": "json",
      "game_levels": "json",
      "core_competences": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}